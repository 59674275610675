import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { RootState } from '../store';
import {constants} from "../config/constants";
import { useLocationBasedBtnColor } from './helpers';
import { useIntl } from 'react-intl';
//setting custom class for error message in modal

export const MobileErrorConfirmAlert = ( message:string,background?:string, title?:string, confirmButtonText?:string, cancelButtonText?:string,extraClass?:string | null | undefined) => {

    return Swal.fire({
        html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${message}</h6>`,
        icon:'error',
        title: title,
        background:background ?? '#151521',
        buttonsStyling: false,
        customClass: {
            confirmButton: `btn btn-primary ${extraClass} btn-sm fs-5`,
            cancelButton: 'btn btn-danger btn-sm fs-5',
        },
        showCancelButton: true,
        confirmButtonText: confirmButtonText ?? 'Confirm',
        cancelButtonText: cancelButtonText ?? 'Cancel',
        width: '29rem',
        heightAuto: false,
    })
}

export const errorAlertWithBtn = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-danger btn-sm fs-5 my-0',
    },
    buttonsStyling: false,
    icon: 'error',
    width: '29rem',
    heightAuto: false,
})
export const errorAlertWithBlackBtn = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-white btn-sm fs-5 my-0',
    },
    buttonsStyling: false,
    icon: 'error',
    width: '29rem',
    heightAuto: false,
})

const successAlertWithBtn = Swal.mixin({
    buttonsStyling: false,
    icon: 'success',
    width: '29rem',
    heightAuto: false,
})

const warningAlertWithBtn = Swal.mixin({
    buttonsStyling: false,
    icon: 'warning',
    width: '29rem',
    heightAuto: false,
})

const confirmAlertWithBtn = Swal.mixin({
    buttonsStyling: false,
    icon: 'error',
    width: '29rem',
    heightAuto: false,
    showDenyButton: true
})

const confirmWarningAlertWithBtn = Swal.mixin({
    buttonsStyling: false,
    icon: 'warning',
    width: '29rem',
    heightAuto: false,
    showDenyButton: true
})

const timerAlert = Swal.mixin({
    icon: 'error',
    timer: 3000,
    timerProgressBar: true,
    allowOutsideClick:false,
    customClass: {
        confirmButton: 'btn btn-warning btn-sm fs-5 my-0',
    },
})

export const messageAlertWithBtn = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-danger btn-sm fs-5 my-0',
    },
    buttonsStyling: false,
    heightAuto: false,
})

export const fireErrorMessage = async (errorMessage: string|number, locationAppearance:number): Promise<SweetAlertResult<ReactElement<any, any>>> => errorAlertWithBtn.fire({
    html: `<h6 class="swal2-html-container fs-5 mx-0 ">${errorMessage}</h6>`,
    background: locationAppearance === 1 //if 1 or light mode set white background
        ? '#fffff'
        : '#151521',
})


/**
 * A custom hook that returns a swal fire function.
 * Caution: The hook requires Location Settings to be loaded.
 */
export const useErrorAlert = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    //EA 09 Feb 2023: Some URLs like: https://devmobile.tabpoint.us/activate Has not an location id set up in the first render.
    /*if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }*/
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings?.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings?.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    return (errorMsg:string, confirmText:string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult<any>> => {
        return errorAlertWithBtn.fire({
            html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
        })
    }
}

export const useTimerAlert = () => {
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    return (errorMsg:string): Promise<SweetAlertResult<any>> => {
        return timerAlert.fire({
            html: `<h6 class="fs-5 my-0 ${alertTextColor}">${errorMsg}</h6>`,
            background: alertBackground,
            heightAuto: false,
        })
    }
}

export const useSuccessAlert = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    const dynamicBtnColor = useLocationBasedBtnColor()
    return (errorMsg:string, confirmText:string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult> => {
        return successAlertWithBtn.fire({
            html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
            customClass: {
                confirmButton: `btn ${dynamicBtnColor} btn-primary btn-sm fs-5 my-0`
            }
        })
    }
}

export const useWarningAlert = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    const dynamicBtnColor = useLocationBasedBtnColor()
    return (errorMsg:string, confirmText:string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult> => {
        return warningAlertWithBtn.fire({
            html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
            confirmButtonText: confirmText,
            background: alertBackground,
            heightAuto: false,
            customClass: {
                confirmButton: `btn ${dynamicBtnColor} btn-primary btn-sm fs-5 my-0`
            }
        })
    }
}

export const useConfirmAlert = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    const alertConfirmTextColor = LocSettings.appearance === APPEARANCE_STATUS.darkMode &&  LocSettings.primary_color === '#ffffff'? 'text-black' : ''
    const dynamicBtnColor = useLocationBasedBtnColor()
    return (errorMsg: string, denyText: string = intl.formatMessage({id:"alert.no_thank_you"}), confirmText: string = intl.formatMessage({id:"alert.proceed"}), allowOutsideClick: boolean = true): Promise<SweetAlertResult<any>> => {
        return confirmAlertWithBtn.fire({
            html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
            denyButtonText: denyText,
            confirmButtonText: confirmText,
            customClass: {
                confirmButton: `btn ${dynamicBtnColor} ${alertConfirmTextColor} btn-primary btn-sm fs-5 my-0 mx-0`,
                denyButton: 'btn btn-danger btn-sm fs-5 my-0'
            },
            allowOutsideClick: allowOutsideClick,
            background: alertBackground,
            heightAuto: false,
        })
    }
}

export const useWarningConfirmAlert = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    const alertConfirmTextColor = LocSettings.appearance === APPEARANCE_STATUS.darkMode &&  LocSettings.primary_color === '#ffffff'? 'text-black' : ''
    const dynamicBtnColor = useLocationBasedBtnColor()
    return (errorMsg: string, denyText: string = intl.formatMessage({id:"alert.no_thank_you"}), confirmText: string = intl.formatMessage({id:"alert.proceed"})): Promise<SweetAlertResult<any>> => {
        return confirmWarningAlertWithBtn.fire({
            html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
            denyButtonText: denyText,
            confirmButtonText: confirmText,
            customClass: {
                confirmButton: `btn ${dynamicBtnColor} ${alertConfirmTextColor} btn-primary btn-sm fs-5 my-0`,
                denyButton: 'btn btn-danger btn-sm fs-5 my-0'
            },
            background: alertBackground,
            heightAuto: false,
        })
    }
}

export const useLoadingAlert = () => {
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    // if(Object.keys(LocSettings).length === 0){
    //     throw new Error('Location Settings Not Loaded!')
    // }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    return (message:string) => {
        return Swal.fire({
            icon: 'warning',
            html: `<h6 class="fs-5 my-0 ${alertTextColor}">${message}</h6>`,
            didOpen(popup: HTMLElement) {
                Swal.showLoading(null)
            },
            background: alertBackground,
            heightAuto: false,
            allowOutsideClick:false
        })
    }
}

export const useAppAlerts = () => {
    const intl = useIntl();
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    if(Object.keys(LocSettings).length === 0){
        throw new Error('Location Settings Not Loaded!')
    }
    const {status, theme} = constants
    const APPEARANCE_STATUS = status.location.appearance
    const alertBackground = LocSettings.appearance === APPEARANCE_STATUS.lightMode ?  theme.lightMode : theme.darkMode
    const alertTextColor = LocSettings.appearance === APPEARANCE_STATUS.lightMode ? 'text-black' : 'text-white'
    const alertConfirmTextColor = LocSettings.appearance === APPEARANCE_STATUS.darkMode && LocSettings.primary_color === '#ffffff' ? 'text-black' : ''
    const dynamicBtnColor = useLocationBasedBtnColor()
    return {
        fireSuccessAlert: (errorMsg: string, confirmText: string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult> => {
            return successAlertWithBtn.fire({
                html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
                customClass: {
                    confirmButton: `btn ${dynamicBtnColor} btn-primary btn-sm fs-5 my-0`
                },
                confirmButtonText: confirmText,
                background: alertBackground,
                heightAuto: false,

            })
        },
        fireErrorAlert: (errorMsg: string, confirmText: string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult<any>> => {
            let message = errorMsg;
            if (Object.values(constants.errors.static).includes(message))
               message = intl.formatMessage({id:message})
            return errorAlertWithBtn.fire({
                html: `<h6 class="fs-5 ${alertTextColor}">${message}</h6>`,
                confirmButtonText: confirmText,
                background: alertBackground,
                heightAuto: false,
            })
        },
        fireStaticColorAlert: (errorMsg: string, confirmText: string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult<any>> => {
            return errorAlertWithBlackBtn.fire({
                html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
                customClass: {
                    confirmButton: `btn ${alertConfirmTextColor} btn-primary btn-sm fs-5 my-0`,
                },
                confirmButtonText: confirmText,
                background: alertBackground,
                heightAuto: false,
            })
        },
        fireLoadingAlert: (message: string) => {
            return Swal.fire({
                icon: 'warning',
                html: `<h6 class="fs-5 my-0 ${alertTextColor}">${message}</h6>`,
                didOpen(popup: HTMLElement) {
                    Swal.showLoading(null)
                },
                background: alertBackground,
                heightAuto: false,
                allowOutsideClick:false
            })
        },
        fireConfirmAlert: (errorMsg: string, denyText: string = intl.formatMessage({id:"alert.no_thank_you"}), confirmText: string = intl.formatMessage({id:"alert.proceed"})): Promise<SweetAlertResult<any>> => {
            return confirmAlertWithBtn.fire({
                html: `<h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6>`,
                customClass: {
                    confirmButton: `btn ${alertConfirmTextColor} btn-primary btn-sm fs-5 my-0`,
                    denyButton: 'btn btn-danger btn-sm fs-5 my-0'
                },
                denyButtonText: denyText,
                confirmButtonText: confirmText,
                background: alertBackground,
                heightAuto: false,
            })
        },
        fireMessageAlert: (errorMsg: string, confirmText: string = intl.formatMessage({id:"alert.ok_got_it"})): Promise<SweetAlertResult<any>> => {
            return messageAlertWithBtn.fire({
                html: `<p><h6 class="fs-5 ${alertTextColor}">${errorMsg}</h6></p>`,
                confirmButtonText: confirmText,
                background: alertBackground,
                heightAuto: false,
            })
        },
    }
}
