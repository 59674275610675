
export interface IReceiptLocation{
    name:string|null,
    address:string|null,
    address2:string|null,
    phone:string|null,
    state_name:string|null,
    city:string|null,
    zip:string|null,
    footer?:string|null
}



export const ReceiptHeader = ({props,displayType}: { props:IReceiptLocation,displayType:number }) =>{
    
    return(
            <div className='text-center text-dark' style={{ fontSize: '17px', textAlign: 'center' }}>
                <div><strong>{props.name ?? ''} </strong></div>
                {
                    displayType>0?
                        <>
                            <div>{props.address ?? ''},  </div>
                            {
                                displayType>1?
                                <>
                                    <div>{(props.address2 !== null) ? props.address2 + ',' : '' } {props.city ?? ''}, {props.state_name ?? ''},{props.zip  ?? ''}  </div>
                                    
                                </>:''
                            }       
                            <div>{props.phone ?? ''}  </div>                     
                        </>:''
                }
            </div>
        )
}