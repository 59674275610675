import {Link} from "react-router-dom";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {useErrorAlert} from "../../../utilities/alerts";
import { useLocationBasedBtnColor } from "../../../utilities/helpers";
import {baseCountries, countries} from "../../../common/country_state/Countries";
import { FormattedMessage, useIntl } from "react-intl";
import SendCodeByEmailForm from "./SendCodeByEmailForm";

export interface ITwoFA{
    phoneNumber:string,
    onCodeSubmit:(code:string)=>void,
    onResendCode:()=>void,
    onResendCodeByEmail?:(email:string, callback:()=>void)=>void,
    phoneCountryId:string
}

const TwoFactorAuthView = ({phoneNumber, onCodeSubmit, onResendCode, onResendCodeByEmail, phoneCountryId}:ITwoFA) => {
    const intl = useIntl();
    const [input1, setInput1] = useState<string>()
    const [input2, setInput2] = useState<string>()
    const [input3, setInput3] = useState<string>()
    const [input4, setInput4] = useState<string>()
    const [input5, setInput5] = useState<string>()
    const [input6, setInput6] = useState<string>()
    const [showSendByCodeEmailPopup, setShowSendByCodeEmailPopup] = useState<boolean>(false)

    const formElement = useRef<null|HTMLFormElement>(null)
    const fireErrorAlert = useErrorAlert()
    const dynamicBtnColor = useLocationBasedBtnColor()

    const resetCode = () => {
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        setInput6('');
    }
    

    const handleBackSpace = (event:  React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === "backspace") {
            event.preventDefault()
            const inputNode = event.target as HTMLInputElement
            if(inputNode.name == 'code_1'){
                setInput1('')
            }
            if(inputNode.name == 'code_2'){
                setInput2('')
            }
            if(inputNode.name == 'code_3'){
                setInput3('')
            }
            if(inputNode.name == 'code_4'){
                setInput4('')
            }
            if(inputNode.name == 'code_5'){
                setInput5('')
            }
            if(inputNode.name == 'code_6'){
                setInput6('')
            }
            const previousInput = inputNode.previousSibling as HTMLInputElement|null
            if(previousInput !== null){
                previousInput.focus()
            }
        }
    };

    const handleAutoNextField = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if(event.target.name == 'code_1'){
            setInput1(event.target.value)
        }
        if(event.target.name == 'code_2'){
            setInput2(event.target.value)
        }
        if(event.target.name == 'code_3'){
            setInput3(event.target.value)
        }
        if(event.target.name == 'code_4'){
            setInput4(event.target.value)
        }
        if(event.target.name == 'code_5'){
            setInput5(event.target.value)
        }
        if(event.target.name == 'code_6'){
            setInput6(event.target.value)
        }
        const inputNode = event.target
        const nextInput = inputNode.nextSibling as HTMLInputElement|null
        if(nextInput !== null){
            nextInput.focus()
            if(nextInput.value != ''){
                nextInput.setSelectionRange(0, 0)
            }
        }
    }

    const HandleSubmit = () => {
        if (input1 && input2 && input3 && input4 && input5 && input6) {
            const code = input1 + input2 + input3 + input4 + input5 + input6
            onCodeSubmit(code)
            resetCode()
        }
        else {
            fireErrorAlert(intl.formatMessage({id:"auth.2fa.enter_verification_code"}))
        }
    }

    const getInputStateValue = (inputNumber:number) => {
        if(inputNumber == 1){
            return input1
        }
        if(inputNumber == 2){
            return input2
        }
        if(inputNumber == 3){
            return input3
        }
        if(inputNumber == 4){
            return input4
        }
        if(inputNumber == 5){
            return input5
        }
        if(inputNumber == 6){
            return input6
        }
        return ''
    }

    const getFormatterPhoneNumber = () => {
        let country_id_found = baseCountries[phoneCountryId];
        let country_code = '';
        if(country_id_found !== undefined && countries[country_id_found['id']]['secondary'] !== undefined) {
            country_code = countries[country_id_found['id']]['secondary']
        }
        let area_code = phoneNumber.slice(0, 3)
        let first_part = phoneNumber.slice(3, 6)
        let last_part = phoneNumber.slice(6, 13)

        let formattedNumber = '';

        if (country_code != '') {
            formattedNumber += `${country_code} `;
        }

        if (area_code) {
            formattedNumber += `(${area_code}) `;
        }

        if (first_part) {
            formattedNumber += `${first_part}-`;
        }

        formattedNumber += last_part;

        return formattedNumber;
    }
    
    return (
        <div className='container-fluid mw-500px px-0 mt-n5'>
            <div className='mx-20px mx-md-0'>
                <div
                    className='form w-100 h-100'
                    id='kt_sing_in_two_steps_form'
                >
                    <div className='text-center logo'>
                        <img alt='smart phone picture' className='mh-125px' src='\media\svg\misc\smartphone-2.svg' />
                    </div>
                    <div className='text-center mb-10'>
                        <h1 className='text-dark mb-3'><FormattedMessage id='common.two_step_verification' defaultMessage='Two Step Verification'/></h1>
                        <div className='text-muted fw-semibold fs-5 mb-5'>
                            <FormattedMessage id='common.enter_verification_code' defaultMessage='Enter the Verification code we sent to' />
                        </div>
                        <div className='text-dark fw-bold fs-3'>{getFormatterPhoneNumber()}</div>
                    </div>
                    <div className='mb-15'>
                        <div className='ms-7 fw-bold text-center text-dark fs-6 mb-1 ms-1'>
                            <FormattedMessage id='common.type_six_digit_code' defaultMessage='Type your 6 digit security code' />
                        </div>
                        <form ref={formElement} id='2fa_form' className='d-flex flex-stack row mt-5 px-5'>
                            {
                                [1,2,3,4,5,6].map((value, index, array)=>{
                                    return (
                                        <input
                                            key={index}
                                            onKeyDown={handleBackSpace}
                                            value={getInputStateValue(value)}
                                            onChange={handleAutoNextField}
                                            onInput={(e: any) => {
                                                console.log(e.target.value.length)
                                                if (e.target.value.length > e.target.maxLength) {

                                                    let index_to_assign = index + 1;
                                                    for (let i = 0; i < e.target.value.length; i++) {
                                                        if(index_to_assign == 1){
                                                            setInput1(e.target.value[i])
                                                        }
                                                        if(index_to_assign == 2){
                                                            setInput2(e.target.value[i])
                                                        }
                                                        if(index_to_assign == 3){
                                                            setInput3(e.target.value[i])
                                                        }
                                                        if(index_to_assign == 4){
                                                            setInput4(e.target.value[i])
                                                        }
                                                        if(index_to_assign == 5){
                                                            setInput5(e.target.value[i])
                                                        }
                                                        if(index_to_assign == 6){
                                                            setInput6(e.target.value[i])
                                                        }
                                                        index_to_assign++;
                                                    }

                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                }
                                            }}
                                            type='number'
                                            name={`code_${value}`}
                                            placeholder=''
                                            maxLength={1}
                                            className='form-control bg-transparent h-50px w-50px fs-2qx text-center col-2'
                                            inputMode='numeric'
                                            autoComplete='one-time-code'
                                            pattern='\d{1}'
                                        />
                                    )
                                })
                            }
                        </form>
                    </div>
                    <div className='d-flex flex-stack justify-content-center '>
                        <button
                            onClick={() => HandleSubmit()}
                            type='button'
                            className='btn btn-lg btn-primary w-100'>
                            <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.submit' defaultMessage='Submit'/></span>
                        </button>
                    </div>
                    <p className='text-muted d-flex flex-stack justify-content-center pt-10'>
                        <FormattedMessage id='common.didnt_get_code' defaultMessage="Didn't get the code ?" />{' '}
                        <Link className='mx-2' onClick={onResendCode} to={'#'}>
                        <FormattedMessage id='common.resend' defaultMessage='Resend' />
                        </Link>
                    </p>
                    {onResendCodeByEmail && (
                        <div className='text-muted d-flex flex-stack justify-content-center'>
                            <Link className='mx-2' onClick={(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>{
                                e.preventDefault();
                                setShowSendByCodeEmailPopup(true)}
                            } to={'#'}>
                                <FormattedMessage id='common.resend_by_email' defaultMessage='Resend code by Email' />
                            </Link> 
                        </div>
                    )}  
                </div>
            </div>
            {onResendCodeByEmail && (
                <SendCodeByEmailForm onHide={()=>{
                    setShowSendByCodeEmailPopup(false)
                }} onSuccess={(email:string) => {
                    onResendCodeByEmail(email, ()=>{
                        setShowSendByCodeEmailPopup(false)
                    })
                }} show={showSendByCodeEmailPopup}  />
            )}
        </div>
    )
}

export default TwoFactorAuthView
