import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {LoadingPage} from "../../../../modules/widgets/Loaders/LoadingPage";
import TooltipComponent from "../../../../utilities/TooltipComponent";
import {Formik, Form, Field} from "formik";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {tsysDomain, useLocationBasedBtnColor} from "../../../../utilities/helpers";
import Inputmask from "inputmask";
import * as ApiCallService from "../../../../services/ApiCallService";
import axios from "axios";
import Swal, {SweetAlertResult} from "sweetalert2";
import {
    createAccountSchemas,
    ICreateAccount,
    inits
} from "../../../../modules/wizards/components/CreateAccountWizardHelper";
import {useIntegrationActivityLog} from "../../../../services/hooks/useIntegrationActivityLog";
import { FormattedMessage, useIntl } from 'react-intl';

export const AddNewCard = ({submitNextStepHandle}:any) => {
    const intl = useIntl();
    const [style, setStyle] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    const [deviceId, setDeviceId] = useState('')
    const [processorEnv, setProcessorEnv] = useState('')
    const [locProcessorId, setLocProcessorId] = useState('')
    const [currentSchema] = useState(createAccountSchemas[0])
    const [initValues] = useState<ICreateAccount>(inits);
    const {sendIntegrationActivityLog, processorIframeDefaultParams} = useIntegrationActivityLog();
    const [formData, setFormData] = useState({
        reusable: 0
    })
    const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true)
    const [scriptAdded, setScriptAdded] = useState(false)
    const [manifestId, setManifestId] = useState('')
    const reservation = useSelector((state: any) => state.reservation)
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
    const SourceFlow = useSelector((state: RootState) => state.Source.flow);
    const reservation_data = reservation.data
    const [displayStoreCard, setDisplayStoreCard] = useState(true)
    const [storeCardValue, setStoreCardValue] = useState(true)
    const dynamicBtnColor = useLocationBasedBtnColor()
    const confirmMessage = (
        <p className='text-gray-800 py-6 mb-0 agree-terms-text form-label fs-6'>
            {' '}
            <FormattedMessage id='common.biometric_terms_condition' defaultMessage="
            By tapping the 'Agree & Confirm' button and providing SoftPoint with your biometric
            information, you consent and provide a written release to SoftPoint's collection, disclosure,
            use and storage of your biometric information and identifiers to facilitate and manage your
            clear application; operate and administer the SoftPoint programs; verify your identity; and
            comply with and enforce legal requirements and policies, as explained in SoftPoint's Privacy
            Policy." />
        </p>
    )

    const re = {
        visa: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/, //ELECTRON remplaced
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        dankort: /^(5019)\d+$/,
        interpayment: /^(636)\d+$/,
        unionpay: /^(62|88)\d+$/,
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
        amex: /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    }
    const detectCardType = (number: any) => {
        number = number.replace(/\s/g, '')
        number = number.replace('_', '')
        number = number.replace('-', '')
        if (re['visa'].test(number)) return 'Visa'
        else if (re['maestro'].test(number)) return 'maestro'
        else if (re['dankort'].test(number)) return 'dankort'
        else if (re['interpayment'].test(number)) return 'interpayment'
        else if (re['unionpay'].test(number)) return 'unionpay'
        else if (re['Visa'].test(number)) return 'Visa'
        else if (re['mastercard'].test(number)) return 'mastercard'
        else if (re['amex'].test(number)) return 'amex'
        else if (re['diners'].test(number)) return 'diners'
        else if (re['discover'].test(number)) return 'discover'
        else if (re['jcb'].test(number)) return 'jcb'
        else return 'no-card'
    }
    useEffect(() => {
        // Phone Number
        Inputmask({
            mask: '9999 9999 9999 9999',
            oncomplete: function () {
                setCardNumber(cardNumber)
                return
            },
            onincomplete: function () {
                fireErrorMessage(intl.formatMessage({id:"common.alert.complete_card_number"}))
                return
            },
        }).mask('#kt_inputmask_2')
    })
    useEffect(() => {
        ApiCallService.ApiCall({}, `/processor/web_session`, 'GET', LocSettings)
            .then((token: any) => {
                setDeviceId(token.data.deviceID)
                setManifestId(token.data.manifest)
                setProcessorEnv(token.data.environment)
            })
            .catch((err) => {
                fireErrorMessage(intl.formatMessage({id:'paymentprocessor.alert.account_is_invalid'}))
                submitNextStepHandle()
            })

        if (LocSettings.store_card_for_room === 1 && reservation.data.rooms_type_id !== null) {
            setDisplayStoreCard(true)
            setStoreCardValue(true)
        } else if (LocSettings.store_card_for_table === 1 && reservation.data.revenue_center_id !== null) {
            setDisplayStoreCard(true)
            setStoreCardValue(true)
        }
    }, [])

    useEffect(() => {
        ApiCallService.ApiCall({}, `/mobile/settings`, 'GET', LocSettings)
            .then((res: any) => {
                setLocProcessorId(res.data.processor.locations_processors_id);
            })
            .catch((err) => {
                fireErrorMessage(intl.formatMessage({id:'paymentprocessor.alert.account_is_invalid'}))
                submitNextStepHandle()
            })

        window.addEventListener("message", processorEventReceiver);
        return () => {
            window.removeEventListener("message", processorEventReceiver);
        }
    }, [])
    useEffect(() => {
        addRemoveTsysScript()
        return () => {
            if(typeof(document.getElementById('tsepTokenizing')) != 'undefined' && document.getElementById('tsepTokenizing') != null) {
                document.getElementById('tsepTokenizing')!.remove();
            }
        }
    }, [deviceId, manifestId])

    const addRemoveTsysScript = () => {
        if (deviceId != "" && manifestId != "") {
            let url = `${tsysDomain(processorEnv)}/transit-tsep-web/jsView/${deviceId}?${manifestId}`
            let log_params:any = processorIframeDefaultParams(LocSettings);
            log_params.url = url

            fetch(url).then(response => response.text()).then(data => {
                if(data.includes('Authentication Failed') || data.includes('ErrorEvent')) {
                    fireErrorMessage(intl.formatMessage({id:"common.alert.processor_not_configured"}))
                    log_params.response = data;
                    log_params.status = 0;
                    sendIntegrationActivityLog(LocSettings, log_params)

                    submitNextStepHandle()
                    return false
                } else {
                    log_params.response = data;
                    log_params.status = 1;
                    sendIntegrationActivityLog(LocSettings, log_params)
                }
            }).catch(error => {
                fireErrorMessage(intl.formatMessage({id:"common.alert.processor_not_configured"}))
                log_params.response = error?.response;
                log_params.status = 0;
                sendIntegrationActivityLog(LocSettings, log_params)

                submitNextStepHandle()
                return false
            })

            /*let skip_config = {
                headers: {
                    'X-Skip-Interceptor': `true`,
                    'Access-Control-Allow-Origin': '*'
                }
            }
            axios.get(`${tsysDomain(processorEnv)}/transit-tsep-web/jsView/${deviceId}?${manifestId}`, skip_config)
                .then(response => {
                    if(response.data.includes('Authentication Failed') || response.data.includes('ErrorEvent')) {
                        fireErrorMessage(intl.formatMessage({id:"common.alert.processor_not_configured"}))
                        submitNextStepHandle()
                        return false
                    }
                })
                .catch(error => {
                    fireErrorMessage(intl.formatMessage({id:"common.alert.processor_not_configured"}))
                    submitNextStepHandle()
                    return false
                })*/

            clearFieldsContainersFromTsys()
            const script = document.createElement('script')
            script.id = 'tsepTokenizing'
            script.src = `${tsysDomain(processorEnv)}/transit-tsep-web/jsView/${deviceId}?${manifestId}`
            script.async = true
            if(typeof(document.getElementById('tsepTokenizing')) != 'undefined' && document.getElementById('tsepTokenizing') != null) {
                document.getElementById('tsepTokenizing')!.remove();
            }
            document.body.appendChild(script)

        }
    }

    const processorEventReceiver = (event: any) => {

        let message = event?.data?.message;
        if (message === undefined) {
            return true;
        }

        var ccnumImg=document.getElementById("CardThumbnailImg");
        if(ccnumImg)ccnumImg.remove();

        //let cardNo = '';
        //var inputValue = (<HTMLInputElement>document.getElementById(elementId)).value;

        let cardNo = (document.getElementById("tsep-cardNum") as HTMLInputElement).value
        let cardDetectedType=detectCardType(cardNo);
        var img = document.createElement("img");

        if(cardDetectedType == 'mastercard')
            img.src ="\\media\\cards\\mc.png";
        else if(cardDetectedType == 'Visa' )
            img.src ="\\media\\cards\\visa.png";
        else if(cardDetectedType == 'discover')
            img.src ="\\media\\cards\\discover.png";
        else if(cardDetectedType == 'amex')
            img.src ="\\media\\cards\\amex.png";
        else
            img.src ="\\media\\cards\\no_card.png";

        img.height=40
        img.id="CardThumbnailImg";
        // var src = document.getElementById("tsep-cardNumDiv");
        // src?.appendChild(img);
        var ccnum=document.getElementById("tsep-cardNum");
        if (ccnum) {
            ccnum.classList.add("ccnum-style");//positions CC image at the end of input field
            ccnum.style.backgroundImage = `url(${img.src})`;
        }

        let messageObj = JSON.parse(message);
        let { data, eventType } = messageObj;

        let month = "";
        let year = "";
        let expirationDate = "";
        let guest_id = 0;

        if (eventType == "TokenEvent") {
            // @ts-ignore
            const elements = document.getElementsByClassName('errorfield') as HTMLInputElement;
            // @ts-ignore
            if(elements != undefined && elements!=null){
                // @ts-ignore
                while(elements.length > 0){
                    // @ts-ignore
                    elements[0].parentNode.removeChild(elements[0]);
                }
            }

            let expDate = (document.getElementById("tsep-cardNum") as HTMLInputElement).value
            let expArr = expDate.split("/");
            var typedYear=parseInt(expArr[1]);
            var currentYear = new Date().getFullYear()
            let averageCCExpireDate = currentYear + 5
            if (currentYear - typedYear > 10 || typedYear > averageCCExpireDate){
                let msg= intl.formatMessage({id:"common.alert.invalid_expire_date"})
                // @ts-ignore
                const elements = document.getElementsByClassName('tsep-datepicker') as HTMLInputElement;
                // @ts-ignore
                if(elements.length>0)
                {
                    // @ts-ignore
                    elements[0].innerHTML=msg
                }
                else{
                    // @ts-ignore
                    const ele = document.getElementById('tsep-datepicker') as HTMLInputElement;
                    // @ts-ignore
                    const label = document.createElement('label')
                    // @ts-ignore
                    label.classList.add('tsep-datepicker')
                    // @ts-ignore
                    label.classList.add('errorfield')
                    // @ts-ignore
                    label.innerHTML=msg
                    // @ts-ignore
                    label.style.color='red'
                    // @ts-ignore
                    ele.parentNode.insertBefore(label, ele.nextSibling);

                }

                setDisabledSubmitBtn(false)
                return false;
            }


            if (data.expirationDate != null && data.tsepToken != '') {
                expirationDate = data.expirationDate.split("/");
                if (expirationDate.length > 1) {
                    month = expirationDate[0];
                    year = expirationDate[1];
                }
            }
            for (var i = 0; i < reservation_data._embedded.guests.guests_count; i++) {
                if (reservation_data._embedded.guests[i].primary == 1) {
                    guest_id = reservation_data._embedded.guests[i].guest_id;
                }
            }
            let postData:any;
            if(SourceFlow != null){
                postData = {
                    guest_id: guest_id,
                    reservation_id: reservation_data.id,
                    cc_token: data.tsepToken,
                    card_type: data.cardType,
                    card_holder: data.cardHolderName,
                    last4: data.maskedCardNumber,
                    exp_month: month.slice(-2),
                    exp_year: year.slice(-2),
                    cvv: data.cvv2,
                    transaction_no: data.transactionID,
                    locations_processor_id: locProcessorId,
                    reusable: storeCardValue ? 1 : 0,
                    flow: SourceFlow
                }

            }else
            {
                postData = {
                    guest_id: guest_id,
                    reservation_id: reservation_data.id,
                    cc_token: data.tsepToken,
                    card_type: data.cardType,
                    card_holder: data.cardHolderName,
                    last4: data.maskedCardNumber,
                    exp_month: month.slice(-2),
                    exp_year: year.slice(-2),
                    cvv: data.cvv2,
                    transaction_no: data.transactionID,
                    locations_processor_id: locProcessorId,
                    reusable: storeCardValue ? 1 : 0
                }

            }
            // @ts-ignore
            setFormData(postData);
            setDisabledSubmitBtn(false)
        }   else if(eventType=='FieldValidationSuccessEvent') {
            // @ts-ignore
            const elements = document.getElementsByClassName(data.fieldName) as HTMLInputElement;
            // @ts-ignore
            if(elements != undefined && elements!=null)
            {
                // @ts-ignore
                while(elements.length > 0){
                    // @ts-ignore
                    elements[0].parentNode.removeChild(elements[0]);
                }
            }
            setDisabledSubmitBtn(true)

            let expDate = (document.getElementById("tsep-datepicker") as HTMLInputElement).value
            let expArr = expDate.split("/");
            var typedYear=parseInt(expArr[1]);
            var currentYear = new Date().getFullYear()
            let averageCCExpireDate = currentYear + 5
            if (currentYear - typedYear > 10 || typedYear > averageCCExpireDate){
                let msg= intl.formatMessage({id:"common.alert.invalid_expire_date"})
                // @ts-ignore
                const elements = document.getElementsByClassName('tsep-datepicker') as HTMLInputElement;
                // @ts-ignore
                if(elements.length>0)
                {
                    // @ts-ignore
                    elements[0].innerHTML=msg
                }
                else{
                    // @ts-ignore
                    const ele = document.getElementById('tsep-datepicker') as HTMLInputElement;
                    // @ts-ignore
                    const label = document.createElement('label')
                    // @ts-ignore
                    label.classList.add('tsep-datepicker')
                    // @ts-ignore
                    label.classList.add('errorfield')
                    // @ts-ignore
                    label.innerHTML=msg
                    // @ts-ignore
                    label.style.color='red'
                    // @ts-ignore
                    ele.parentNode.insertBefore(label, ele.nextSibling);

                }

                setDisabledSubmitBtn(false)
            }
        }
        else if (eventType == 'FieldValidationErrorEvent' || eventType == 'FieldValidationBlankEvent') {
            let msg=data.message
            msg=msg.replace("CardNumber", "Card Number");
            msg=msg.replace("Expiry", "Expiration");
            // @ts-ignore
            const elements = document.getElementsByClassName(data.fieldName) as HTMLInputElement;
            // @ts-ignore
            if(elements.length>0)
            {
                // @ts-ignore
                elements[0].innerHTML=msg
            }
            else{
                // @ts-ignore
                const ele = document.getElementById(data.fieldName) as HTMLInputElement;
                // @ts-ignore
                const label = document.createElement('label')
                // @ts-ignore
                label.classList.add(data.fieldName)
                // @ts-ignore
                label.classList.add('errorfield')
                // @ts-ignore
                label.innerHTML=msg
                // @ts-ignore
                label.style.color='red'
                // @ts-ignore
                ele.parentNode.insertBefore(label, ele.nextSibling);

            }
            setDisabledSubmitBtn(true)
        }
    }

    useEffect(() => {
        if(document.getElementById('tsep-cardHolderName') !==null){
            setScriptAdded(true)
        }
    })

    useEffect(() => {
        setTimeout(function () {
            setStyle(!style)
            if (!document.getElementById('tsep-cardHolderName')?.classList.contains("form-control")) {
                document.getElementById('tsep-cardHolderName')?.classList.add('form-control')
                document.getElementById('tsep-cardHolderName')?.classList.add('form-control-solid')
                document.getElementById("tsep-cardHolderName")?.setAttribute("tabindex", "1");
                document.getElementById("tsep-cardHolderName")?.setAttribute("autocomplete", "tsep-cardHolderName");
            }

            if (!document.getElementById('tsep-cardNum')?.classList.contains("form-control")) {
                document.getElementById('tsep-cardNum')?.classList.add('form-control')
                document.getElementById('tsep-cardNum')?.classList.add('form-control-solid')
                document.getElementById('tsep-cardNum')?.setAttribute('type','tel')
                document.getElementById('tsep-cardNum')?.setAttribute("tabindex", "2");
                document.getElementById("tsep-cardNum")?.setAttribute("autocomplete", "tsep-cardNum");
            }

            if (!document.getElementById('tsep-cvv2')?.classList.contains("form-control")) {
                document.getElementById('tsep-cvv2')?.classList.add('form-control')
                document.getElementById('tsep-cvv2')?.classList.add('form-control-solid')
                document.getElementById('tsep-cvv2')?.setAttribute('type','tel')
                document.getElementById("tsep-cvv2")?.setAttribute("tabindex", "4");
                document.getElementById("tsep-cvv2")?.setAttribute("autocomplete", "tsep-cvv2");
            }
            if (!document.getElementById('tsep-datepicker')?.classList.contains("form-control")) {
                document.getElementById('tsep-datepicker')?.classList.add('form-control')
                document.getElementById('tsep-datepicker')?.classList.add('form-control-solid')
                document.getElementById('tsep-datepicker')?.setAttribute('type','tel')
                document.getElementById('tsep-datepicker')?.setAttribute('inputmode','tel')
                document.getElementById("tsep-datepicker")?.setAttribute("tabindex", "3");
                document.getElementById("tsep-datepicker")?.setAttribute("autocomplete", "tsep-datepicker");
            }

            if (!document.getElementById('tsep-zipCode')?.classList.contains("form-control")) {
                document.getElementById('tsep-zipCode')?.classList.add('form-control')
                document.getElementById('tsep-zipCode')?.classList.add('form-control-solid')
                document.getElementById('tsep-zipCode')?.setAttribute('type', 'tel')
                document.getElementById('tsep-zipCode')?.setAttribute('inputmode', 'tel')
                document.getElementById("tsep-zipCode")?.setAttribute("tabindex", "5");
                document.getElementById("tsep-zipCode")?.setAttribute("autocomplete", "tsep-zipCode");
            }
        }, 500)
    }, [style])

    useEffect(() => {
        if (scriptAdded) 
          addSpaceValidationForNameAndZip();
      }, [scriptAdded])

    function addSpaceValidationForNameAndZip(){
        ["tsep-cardHolderName","tsep-zipCode"].forEach(element => {
            if(document.getElementById(element) !== null){
                const nameInput = document.getElementById(element);
                nameInput?.addEventListener('keydown', validateOnlySpaces);
                nameInput?.addEventListener('blur', trimSpaces);
            } 
        });
    }

    function validateOnlySpaces(event:any) {
        if (event.key === ' ' && event.target.value.trim() === '') 
            event.preventDefault();
        else if(event.target.value.indexOf('  ') > -1){
            const corrected = event.target.value.replace('  ', ' ');
            event.target.value = corrected;
        }
    }

    function trimSpaces(event:any) {
        event.target.value  = event.target.value.trim();
    }
 
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        buttonsStyling: false,
        icon: 'error',
        background: LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
    })
    const fireErrorMessage = async (
        _errorMessage: string
    ): Promise<SweetAlertResult<ReactElement<any, any>>> =>
        swalWithBootstrapButtons.fire({
            html: `<h6 class="swal2-html-container fs-5 mx-0 gray-800-dark">${_errorMessage}</h6>`,
        })

    const handleClickEventPayment = (e: any) => {
        if(!disabledSubmitBtn) {
            const postData = formData;

            if (Object.keys(postData).length > 0) {
                postData.reusable = storeCardValue ? 1 : 0
                ApiCallService.ApiCall(postData, `/guest_cc_token`, 'POST', LocSettings)
                    .then((res: any) => {
                        if(res?.data?.id != undefined && res.data.id > 0) {
                            submitNextStepHandle()
                        } else {
                            fireErrorMessage(intl.formatMessage({id:"common.alert.invalid_credit_card"}))
                        }
                    })
                    .catch((err) => {
                        fireErrorMessage(intl.formatMessage({id:"common.alert.invalid_credit_card"}))
                        //submitNextStepHandle()
                    })
            } else {
                fireErrorMessage(intl.formatMessage({id:"common.alert.provide_all_detail"}))
            }
        }

    };

    const clearFieldsContainersFromTsys = () => {
        // @ts-ignore
        document.getElementById('tsep-cardHolderNameDiv').innerHTML = ''
        // @ts-ignore
        document.getElementById('tsep-cardNumDiv').innerHTML = ''
        // @ts-ignore
        document.getElementById('tsep-datepickerDiv').innerHTML = ''
        // @ts-ignore
        document.getElementById('tsep-cvv2Div').innerHTML = ''
        // @ts-ignore
        document.getElementById('tsep-zipCodeDiv').innerHTML = ''
    }

    return (
        <>
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </head>
            <div  className={ scriptAdded?"d-none":""}>
                <LoadingPage />
            </div>
            <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={()=>{}}
            >
                <Form noValidate>
                    <div className={ scriptAdded?"d-flex p-5 card card-rounded flex-column w-100":"d-none d-flex flex-column w-100"}>
                        <div className='w-100'>
                            <div className='d-flex flex-column mb-2 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                    <span className='required'><FormattedMessage id='common.name_on_card' defaultMessage='Name On Card' /></span>
                                </label>
                                <div id='tsep-cardHolderNameDiv' data-validate-name='Y'></div>
                            </div>

                            <div className='d-flex flex-column mb-2 fv-row'>
                                <label className='required fs-6 fw-bold form-label mb-2'><FormattedMessage id='common.card_number' defaultMessage='Card Number' /></label>
                                <div className='position-relative'>
                                    <div
                                        id='tsep-cardNumDiv'
                                        data-auto-formatting='Y'
                                        data-validate-cc='Y'
                                        data-detect-card-type='Y'
                                    ></div>

                                </div>
                            </div>

                            <div className='row mb-5'>
                                <div className='col-md-6 fv-row'>
                                    <label className='required fs-6 fw-bold form-label mb-2'><FormattedMessage id='common.expire_date' defaultMessage='Expiration Date' /></label>
                                    <div className='row fv-row'>
                                        <div id='tsep-datepickerDiv' data-validate-expiry-date='Y'></div>
                                    </div>
                                </div>
                                <div className='col-md-6 fv-row'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                        <span className='required'><FormattedMessage id='common.cvv' defaultMessage='CVV' /></span>
                                        <TooltipComponent title={intl.formatMessage({id:"common.cvv_tooltip"})}>
                                            <i
                                                className='fas fa-exclamation-circle ms-2 fs-7'
                                            />
                                        </TooltipComponent>
                                    </label>

                                    <div className='position-relative'>
                                        <div id='tsep-cvv2Div' data-validate-cvv2='Y'></div>
                                        <div className='position-absolute translate-middle-y top-50 end-0 me-3'></div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-column mb-5 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                    <span className='required'><FormattedMessage id='common.zip_code' defaultMessage='Zip Code'/></span>
                                </label>
                                <div id='tsep-zipCodeDiv' data-validate-zipcode='Y'></div>

                            </div>

                            {displayStoreCard &&
                                <div className='d-flex flex-stack'>
                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                        <Field className='form-check-input' type='checkbox' checked={storeCardValue}
                                               onChange={(e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => { setStoreCardValue(e.target.checked) }} />
                                        <span className='form-check-label fw-bold text-gray-400'>
                                        <FormattedMessage id='common.save_card_for_future_reservation' defaultMessage='Save Card for future reservations?' />
                                </span>
                                    </label>
                                </div>
                            }
                        </div>

                        <div className='text-muted d-flex flex-justify-center flex-column'>
                            {confirmMessage}
                            <div className='w-100'>
                                <button onClick={handleClickEventPayment} type='button' className='btn btn-lg btn-primary w-100'>
                                    <span className={`indicator-label ${dynamicBtnColor}`}><FormattedMessage id='common.agree_confirm' defaultMessage='Agree & Confirm' /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    )
}
